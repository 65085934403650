// Global CSS Must Be in the Custom APP
import 'nprogress/nprogress.css';
import 'resources/override.css';
import 'resources/fonts.css';
import '@finn/design-system/setup';

import DevModal from '@finn/b2c-cp/deprecated/modules/dev/DevModal';
import useSetDevMode from '@finn/b2c-cp/deprecated/modules/dev/hooks/useSetDevMode';
import ModalContainer from '@finn/b2c-cp/deprecated/modules/modals/components/ModalContainer';
import { ModalKey } from '@finn/b2c-cp/deprecated/modules/modals/constants';
import { ToastManager } from '@finn/design-system/atoms/toast';
import { setCurrentLang, setMessages } from '@finn/platform-modules';
import { MobileAppBindings } from '@finn/ua-app/layouts/MobileAppRootLayout';
import { UserAccountProvider } from '@finn/ua-auth';
import { FULL_STORY_INITIALIZATION_DELAY } from '@finn/ua-constants';
import { AppProvider } from '@finn/ui-modules';
import {
  App,
  config,
  CookieKeys,
  CurrentAppContext,
  DefaultLocale,
  FakeDefaultLocale,
  formatUtmSourceValue,
  isAllConsentTrue,
  isCookieExist,
  isMobileApp,
  isServer,
  loadCurrentLocale,
  Locale,
  pageEvent,
  renderSegmentSnippet,
  setClientCookie,
  setFinnSessionCookie,
  UCConsentEvent,
  updateSegmentIntegration,
} from '@finn/ui-utils';
import Cookies from 'js-cookie';
import trimEnd from 'lodash/trimEnd';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { SessionProvider } from 'next-auth/react';
import queryString from 'query-string';
import { useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { SWRConfig } from 'swr';

import { getCPRichTextElements } from '../features-data/getCPRichTextElements';

const useInitB2CApp = () => {
  useSetDevMode();

  useEffect(() => {
    // we check UC_Integrations and notify segment about what can be tracked
    if (window.UC_Integrations && window.dataLayer) {
      const ucAnalyticsService = 'Google Analytics';
      const ucAdService = 'Adwords Remarketing Lists';
      const ucAdWords = 'AdWords';
      window.dataLayer.push([
        'consent',
        'update',
        {
          ad_storage:
            window.UC_Integrations[ucAdService] ||
            window.UC_Integrations[ucAdWords]
              ? 'granted'
              : 'denied',
          analytics_storage: window.UC_Integrations[ucAnalyticsService]
            ? 'granted'
            : 'denied',
        },
      ]);
    }
    window.dispatchEvent(new Event('afterSegmentLoad'));

    const parsedURLQuery = queryString.parse(window.location.search);
    let utmSourceValue = parsedURLQuery.utm_source;
    if (Array.isArray(utmSourceValue)) {
      utmSourceValue = utmSourceValue[0];
    }

    const isUTMCookieExist = isCookieExist(CookieKeys.UTM_SOURCE);

    // we set utm source for marketing
    if (utmSourceValue && !isUTMCookieExist) {
      const formattedSourceValue = formatUtmSourceValue(utmSourceValue);
      setClientCookie(CookieKeys.UTM_SOURCE, formattedSourceValue, 30);
    }

    // now we setup fullstory and segment analutics
    window.addEventListener('afterConsent', (event: Event) => {
      const consent = event as UCConsentEvent;
      const detail = { ...consent.detail };
      if (detail?.type === 'explicit' || isAllConsentTrue(detail)) {
        const detailCopy: Record<string, string> = { ...detail };
        delete detailCopy.event;
        delete detailCopy.type;
        delete detailCopy.action;

        updateSegmentIntegration(detailCopy);
        window.analytics?.load?.(config.ANALYTICS_WRITE_KEY, {
          integrations: window.UC_Integrations,
        });
        window.dispatchEvent(new Event('afterSegmentLoad'));
        const isFullStoryEnabled =
          Cookies.get(CookieKeys.FULLSTORY_DE) === 'enabled';
        if (isFullStoryEnabled && !!detail.Fullstory === true) {
          window.dataLayer = window.dataLayer || [];
          setTimeout(() => {
            window.dataLayer.push({ event: 'FullStoryCookie' });
          }, FULL_STORY_INITIALIZATION_DELAY);
        }
      }
    });

    // we set tracking cookie
    setFinnSessionCookie();
  }, []);
};

const CustomApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter();
  const localeToLoad =
    router.locale === FakeDefaultLocale
      ? DefaultLocale
      : router.locale || DefaultLocale;

  const { locale, messages } = useMemo(
    () => loadCurrentLocale(localeToLoad),
    [localeToLoad]
  );

  // sets all the app related side effects
  useInitB2CApp();

  useEffect(() => {
    if (router.isReady) {
      let path = router.asPath;
      if (router.locale !== FakeDefaultLocale) {
        path = trimEnd(`/${router.locale}${router.asPath}`, '/');
      }
      pageEvent(path);
    }
  }, [router.asPath, router.isReady, router.locale]);

  const withNewAppBindings =
    (!router?.asPath?.includes('/mobile') ||
      router?.asPath?.includes('/register') ||
      router?.asPath?.includes('/authv2')) &&
    isMobileApp();

  setCurrentLang(locale);
  setMessages(messages);

  if (typeof window !== 'undefined' && locale === 'en-US') {
    window.location.href = `${window.location.origin}/${locale}/no-service`;
  }

  return (
    <AppProvider>
      <style>
        {`
          #__next {
            display: flex;
            flex-direction: column;
            min-height: 100vh;
          }
        `}
      </style>
      {/*
        We supress cookie banner for mobile app
        https://docs.usercentrics.com/#/cmp-v2-ui-api?id=suppress-the-cmp
      */}
      {!isServer() && !window?.analytics && (
        <Script
          dangerouslySetInnerHTML={{
            __html: renderSegmentSnippet(),
          }}
        />
      )}
      {pageProps.suppressCookieBanner || isMobileApp() ? (
        <Script>{`var UC_UI_SUPPRESS_CMP_DISPLAY=true;`}</Script>
      ) : null}
      <Script
        id="usercentrics-cmp"
        src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
        data-settings-id={config.USERCENTRICS_ID}
      />
      <IntlProvider
        locale={locale}
        defaultLocale={Locale.GERMAN_GERMANY}
        messages={messages}
        wrapRichTextChunksInFragment={true}
        defaultRichTextElements={getCPRichTextElements()}
      >
        <SessionProvider session={pageProps.session}>
          <SWRConfig
            value={{
              revalidateIfStale: false,
              revalidateOnFocus: false,
              revalidateOnReconnect: false,
            }}
          >
            <CurrentAppContext.Provider value={App.CP}>
              <UserAccountProvider>
                <Component {...pageProps} />
                <ModalContainer
                  name={ModalKey.DEV_MODAL}
                  ModalComponent={DevModal}
                />
                {withNewAppBindings && <MobileAppBindings />}
              </UserAccountProvider>
            </CurrentAppContext.Provider>
            <ToastManager />
          </SWRConfig>
        </SessionProvider>
      </IntlProvider>
    </AppProvider>
  );
};

export default CustomApp;
